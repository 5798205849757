import { emDESENVOLVIMENTO } from "./setupDesenvolvimento"

// TODO - ALTERAR EM PRODUCAO - e ALTERAR database.json

/*
--> Dentro do DATABASE.JSON alterar o production para producao em testes.....
*/

const hostBACKEND = emDESENVOLVIMENTO ? 'localhost' : 'www.integrarsistemas.com.br'
// const hostBACKEND = 'www.4doctor.com.br'
//const hostBACKEND = '192.168.1.127'

// const pathCERTIFICADO = '/etc/letsencrypt/live/'.concat( hostBACKEND ).concat( '/' )
const pathCERTIFICADO = '/etc/letsencrypt/live/app.forhealth.com.br/'

const portBACKEND = 4010

const ssl = !emDESENVOLVIMENTO

export const configBACKEND =
{
  ssl: ssl,
  port: portBACKEND,
  hostname: hostBACKEND,
  httpEndpoint: 'http'.concat( emDESENVOLVIMENTO ? '' : 's' ).concat( '://' ).concat( hostBACKEND ).concat( ':' ).concat( portBACKEND.toString() ).concat( '/graphql' ),
  httpBackEnd: 'http'.concat( emDESENVOLVIMENTO ? '' : 's' ).concat( '://' ).concat( hostBACKEND ).concat( ':' ).concat( portBACKEND.toString() ).concat( '/' ),
  pathCERTIFICADO: pathCERTIFICADO
}

export const DTFORMAT = Object.freeze( {
  USUARIO: 'DD/MM/YYYY',
  USUARIO_DATETIME: 'DD/MM/YYYY HH:mm:ss',
  BANCO: 'YYYY-MM-DD',
  UNIX: 'x'
} )